import React from "react";
import PropTypes from "prop-types";
import {
  SelectField,
  SelectOption,
  DropdownIcon,
  DropdownContainer,
  FieldText,
} from "./DropdownRecords.styled";
import { Trans, useTranslation } from "react-i18next";
const numbers = [5, 10, 20, 50];
const DropdownRecords = (props) => {
  const { t } = useTranslation();
  return (
    <DropdownContainer>
      <FieldText>{t("table.dropdown.desc1")}</FieldText>
      <SelectField
        defaultValue={10}
        onChange={(value) => {
          props?.onChange(value.target.value);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: -160,
            horizontal: "center",
          },
        }}
        value={props?.value}
        IconComponent={() => <DropdownIcon />}
      >
        {numbers?.map?.((number, index) => {
          return (
            <SelectOption key={index} value={number}>
              {number}
            </SelectOption>
          );
        })}
      </SelectField>
      <FieldText>
        <Trans
          t={t}
          i18nKey={"table.dropdown.desc2"}
          values={{ number: props?.totalElements }}
          components={[<FieldText setColor={true} key={1} />]}
        />
      </FieldText>
    </DropdownContainer>
  );
};

DropdownRecords.propTypes = {
  totalElements: PropTypes.number,
  onChange: PropTypes.func,
  value: PropTypes.number,
};

export default DropdownRecords;
