import React from "react";
import PropTypes from "prop-types";
import { Tooltip as MuiTooltip } from "@mui/material";
import { TooltipInnerContainer } from "./Tooltip.styled";

const Tooltip = (props) => {
  return (
    <MuiTooltip
      {...props}
      arrow
      // className="tooltip"
      PopperProps={{
        sx: {
          "& .MuiTooltip-tooltip": {
            maxWidth: props?.maxWidth,
            padding: "12px 16px",
            backgroundColor: "#fff",
            color: "#000",
            fontSize: "14px",
            fontFamily: "Inter",
            boxShadow: "0px 8px 23px -9px rgba(130,123,130,1)",
            borderRadius: "8px",
          },
          "& .MuiTooltip-arrow": {
            color: "#fff",
          },
        },
      }}
    >
      <TooltipInnerContainer {...props?.innerContainerProps}>
        {props?.children}
      </TooltipInnerContainer>
    </MuiTooltip>
  );
};

Tooltip.propTypes = {
  children: PropTypes.node,
  innerContainerProps: PropTypes.any,
  maxWidth: PropTypes.string,
};

export default Tooltip;
