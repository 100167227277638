import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";

export const FindingScanningQuestionsHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: -32px;
`;
export const FindingScanningQuestionsHeaderTitle = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: -0.02em;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.pageTitleColor};
`;
export const FindingQuestionContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;
