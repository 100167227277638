import { Box, TableCell, TextareaAutosize } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";
import { hexToRGB } from "../../../../../../util/helpers/colorHelper";
import { TrashIcon } from "../../../../../ImagePicker/ImagePreview/ImagePreview.styled";
import CopyIcon from "../../../../../Icon/Icons/CopyIcon";
import Tooltip from "../../../../../Tooltip/Tooltip";

export const TableCellInput = styled(TextareaAutosize)`
  border: 0;
  resize: none;
  height: 100%;
  margin-top: 2px;
  /* width: 100%; */
  font-family: Inter;
  padding: 0;
  &:focus-visible {
    border: 0;
    border-image-width: 0;
    outline-width: 0;
  }
`;

export const FindingItemTableCellContainer = styled(TableCell)`
  position: relative;
  overflow: visible;
  /* width: 100%; */
  text-align: ${(props) => props?.$isHeaderCell && "center"};
  /* white-space: ${(props) => props?.$isHeaderCell && "nowrap"}; */
  border: ${(props) =>
    props?.$isFocused
      ? `1px solid ${selectedTheme.colors.orange}`
      : `0.5px solid ${selectedTheme.colors.questionSeparator}`};
  border-top-left-radius: ${(props) => props?.$firstInFirstRow && "12px"};
  border-bottom-left-radius: ${(props) => props?.$firstInLastRow && "12px"};
  border-top-right-radius: ${(props) => props?.$lastInFirstRow && "12px"};
  border-bottom-right-radius: ${(props) => props?.$lastInLastRow && "12px"};
  min-height: 36px;
  height: 36px;
  padding: 8px;
  & * {
    font-family: ${selectedTheme.fonts.mainText};
  }
  background-color: ${(props) =>
    props?.$isHeaderCell && selectedTheme.colors.pagingSelected};
  /* flex: 1;
  display: flex; */
  & textarea {
    background-color: white;
  }
  & textarea:nth-child(1) {
    width: 100%;
  }
  & ${TableCellInput} {
    background-color: ${(props) =>
      props?.$isHeaderCell && selectedTheme.colors.pagingSelected};
  }
`;
export const ToolsContainer = styled(Box)`
  position: absolute;
  display: ${(props) => (props?.$isFocused ? "flex" : "none")};
  gap: 4px;
  z-index: 20;
  bottom: -10px;
  right: 10px;
`;
export const IconContainer = styled(Tooltip)`
  border-radius: 100%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50px;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  background: ${selectedTheme.colors.white};
  box-shadow: 0px 2px 4px -2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.0)},
    0px 4px 8px -2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.1)};
`;
export const RemoveRowIcon = styled(TrashIcon)`
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
`;
export const CopyRowIcon = styled(CopyIcon)`
  width: 12px;
  height: 12px;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
`;
export const IconInnerContainer = styled(Box)``;
