import React from "react";
import { LoginImageContainer } from "../LoginContent/LoginContent.styled";
import { Image } from "./LoginImage.styled";

const LoginImage = () => {
  return (
    <LoginImageContainer>
      <Image />
    </LoginImageContainer>
  );
};

export default LoginImage;
