import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import DeleteIcon from "../../../../Icon/Icons/DeleteIcon";
import PlusCircledIcon from "../../../../Icon/Icons/PlusCircledIcon";
import selectedTheme from "../../../../../themes";
import NewEyeIcon from "../../../../Icon/Icons/NewEye";

export const ColumnsContainer = styled(Box)`
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const TitleContainer = styled(Box)`
  display: flex;
`;

export const RemoveColumnIcon = styled(DeleteIcon)`
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
`;
export const AddColumnIcon = styled(PlusCircledIcon)`
  cursor: pointer;
  margin-top: 10px;
  width: 20px;
  height: 20px;
`;

export const AddColumnText = styled(Typography)`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  text-align: left;
  color: ${selectedTheme.colors.orange};
  font-family: ${selectedTheme.fonts.mainText};
`;

export const AddContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: flex-end;
  cursor: pointer;
  width: max-content;
`;
export const PreviewContainer = styled(Box)`
  display: flex;
  gap: 8px;
  margin-top: 12px;
  cursor: pointer;
  @media (max-width: 600px) {
    display: none;
  }
`;
export const PreviewIcon = styled(NewEyeIcon)``;
export const PreviewText = styled(Box)`
  color: ${selectedTheme.colors.profilePictureText};
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
`;
