import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../../../themes";
import PlusIcon from "../../../../../../../Icon/Icons/PlusIcon";
import EditIcon from "../../../../../../../Icon/Icons/EditIcon";

export const TemplateSectionsListItemDetailsContainer = styled(Box)`
  padding-bottom: 16.5px;
  padding-top: 16.5px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  border-bottom: ${(props) =>
    props?.$chosen && `3px solid ${selectedTheme.colors.pageTitleColor}`};
`;
export const TemplateSectionsListItemTitle = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: ${(props) => (props?.$chosen ? 600 : 500)};
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${(props) =>
    props?.$chosen
      ? selectedTheme.colors.pageTitleColor
      : selectedTheme.colors.tabUnselectedColor};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 170px;
`;
export const DeleteSectionIcon = styled(PlusIcon)`
  transform: rotate(45deg);
  cursor: pointer;
  width: 18px;
  height: 18px;
  & path {
    stroke: black;
  }
`;
export const EditSectionIcon = styled(EditIcon)`
  cursor: pointer;
`;
