import React, { forwardRef, useImperativeHandle, useState } from "react";
import PropTypes from "prop-types";
import {
  ChapterContainer,
  ChapterTitle,
  TextInput,
  TextareaContainer,
} from "../../SetScanningObjectModal.styled";
import { useTranslation } from "react-i18next";
import TextareaInput from "../../../../Field/TextareaInput/TextareaInput";

const TextTypeInputs = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const [defaultAnswer, setDefaultAnswer] = useState(props?.defaultAnswer);
  const [note, setNote] = useState(props?.note);

  useImperativeHandle(ref, () => ({
    defaultAnswer,
    setDefaultAnswer,
    note,
    setNote,
  }));

  const handleChangeDefaultAnswer = (newAnswer) => {
    setDefaultAnswer(newAnswer);
  };
  const handleChangeNote = (event) => {
    setNote(event.target.value);
  };

  return (
    <>
      <TextareaContainer>
        <ChapterTitle>{t("questions.defaultAnswerOptional")}</ChapterTitle>
        <TextareaInput
          value={defaultAnswer}
          onBlur={handleChangeDefaultAnswer}
          minRows={3}
          maxRows={6}
          isRichText
          placeholder={t("questions.defaultAnswerPlaceholder")}
        />
      </TextareaContainer>
      <ChapterContainer>
        <ChapterTitle>{t("questions.noteOptional")}</ChapterTitle>
        <TextInput
          value={note}
          onChange={handleChangeNote}
          minRows={3}
          placeholder={t("questions.noteOptionalPlaceholder")}
        />
      </ChapterContainer>
    </>
  );
});

TextTypeInputs.displayName = "TextTypeInputs";

TextTypeInputs.propTypes = {
  defaultAnswer: PropTypes.string,
  note: PropTypes.string,
};

TextTypeInputs.defaultProps = {
  defaultAnswer: "",
  note: "",
};

export default TextTypeInputs;
