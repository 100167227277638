import React from "react";
import PropTypes from "prop-types";
import { LoginHeaderContainer, LoginTitle, Logo } from "./LoginHeader.styled";
import { useTranslation } from "react-i18next";
import logo from "../../../assets/images/svg/company-logo-default.svg";

const LoginHeader = () => {
  const { t } = useTranslation();
  return (
    <LoginHeaderContainer>
      <Logo src={logo} />
      <LoginTitle>{t("login.logInTitle")}</LoginTitle>
    </LoginHeaderContainer>
  );
};

LoginHeader.propTypes = {
  children: PropTypes.node,
};

export default LoginHeader;
