import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../themes";
import CopmanyLogoDefault from "../../Icon/Icons/CompanyLogoDefault";

export const SidebarLogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 13px;
`;

export const SidebarLogoTitle = styled.span`
  font-family: ${selectedTheme.fonts.mainText};
  font-size: 24px;
  font-weight: 600;
  margin-left: 20px;
  max-width: 171px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  white-space: nowrap;
`;
export const SidebarLogoImageContainer = styled(Box)`
  position: relative;
`;
export const SidebarLogoIcon = styled(CopmanyLogoDefault)`
  width: 40px;
  height: 33px;
`;

export const LogoImage = styled.img`
  max-width: 48px;
  max-height: 48px;
`;
