import React from "react";
import PropTypes from "prop-types";
import {
  FindingTypeLabelContainer,
  RadioButton,
} from "./FindingTypeLabel.styled";

const FindingTypeLabel = (props) => {
  return (
    <FindingTypeLabelContainer
      $isChecked={props?.isChecked}
      label={props?.label}
      value={props?.value}
      control={
        <RadioButton
          isChecked={props?.isChecked}
          icon={props?.uncheckedIcon ?? props?.uncheckedIcon}
          checkedIcon={props?.checkedIcon ?? props?.checkedIcon}
        />
      }
    />
  );
};

FindingTypeLabel.propTypes = {
  isChecked: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.any,
  uncheckedIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
  checkedIcon: PropTypes.oneOfType([PropTypes.node, PropTypes.element]),
};

export default FindingTypeLabel;
