import { Box, Typography } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../../../themes";
import { hexToRGB } from "../../../../../../util/helpers/colorHelper";

export const EmptyMeasuringInstrumentsTableContainer = styled(Box)`
  background: white;
  border: 1px solid ${selectedTheme.colors.tableBorder};
  box-shadow: 0px 1px 2px ${hexToRGB(selectedTheme.colors.shadowColor, 0.05)};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  gap: 9px;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: center;
`;
export const EmptyMeasuringInstrumentsText = styled(Typography)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.emptyMeasuringInstrumentsPlaceholder};
`;
