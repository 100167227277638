export const lowPriority = 1;

export const fontSizes = [
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "17px",
  "18px",
  "19px",
  "20px",
];

export const BOLD = "bold";
export const ITALIC = "italic";
export const UNDERLINE = "underline";
export const LEFT_ALIGN = "left";
export const CENTER_ALIGN = "center";
export const RIGHT_ALIGN = "right";
export const JUSTIFY = "justify";
export const FONT_SIZE = "font-size"
export const ORDERED_LIST = "ol";
export const UNORDERED_LIST = "ul";
export const PARAGRAPH = "paragraph"