import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../../../themes";
import Select from "../../../Select/Select";
import Tooltip from "../../../Tooltip/Tooltip";

export const FindingTabChooserContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 600px) {
    overflow-y: scroll;
    max-width: calc(100vw - 26px);
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;
export const FindingAllTabsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex: 1;
  align-items: center;
  @media (max-width: 600px) {
    overflow-y: scroll;
    max-width: calc(100vw - 26px);
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;
export const FindingTab = styled(Box)`
  padding-bottom: 16px;
  white-space: nowrap;
  border-bottom: 3px solid
    ${(props) =>
      props?.selected ? selectedTheme.colors.pageTitleColor : "transparent"};
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.pageTitleColor};
  cursor: pointer;
`;

export const FindingSSTab = styled(FindingTab)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CustomTooltip = styled(Tooltip)`
  max-width: 100px;
`;

export const TabsVerticalSeparator = styled(Box)`
  width: 1px;
  height: 23px;
  background-color: ${selectedTheme.colors.tabsSeparator};
`;
export const TabsHorizontalSeparator = styled(Box)`
  width: 100%;
  height: 1px;
  background-color: ${selectedTheme.colors.questionSeparator};
  position: relative;
  top: -2px;
  z-index: -1;
`;
export const FindingTabsOutterContainer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const FindingTabsContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  width: 100%;
  gap: 24px;
  align-items: center;
  flex-wrap: ${props => props?.$isWrapping};
  max-height: 40px;
  @media (max-width: 600px) {
    flex-wrap: nowrap;
  }
`;
export const OtherTabsSelector = styled(Select)`
  /* & > div:nth-child(2) {
    display: none;
  } */
  border-width: 0;
  max-height: 39px;
  & * {
    border-width: 0;
    max-height: 39px;
    box-shadow: none;
  }
  & > div:nth-child(2) > div:nth-child(1) {
    /* width: 14px; */
    /* overflow: hidden; */
    padding: 0;
  }
  & svg {
    display: none;
  }
`;
export const FindingModalButtonsContainer = styled(Box)`
  display: flex;
  gap: 4px;
  align-items: center;
`;
export const FindingModalSingleButtonContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${selectedTheme.colors.inputBorder};
  border-radius: 8px;
  padding: 10px 12px;
  cursor: pointer;
  & path {
    stroke: ${selectedTheme.colors.gray};
  }
  @media (max-width: 600px) {
    & svg {
      width: 24px;
      height: 24px;
    }
  }
`;
