import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  SidebarBackdrop,
  SidebarContainer,
  SidebarNavigationContainer,
} from "./SidebarNavigation.styled";
import SidebarUpperContent from "./SidebarUpperContent/SidebarUpperContent";
import SidebarLowerContent from "./SidebarLowerConent/SidebarLowerContent";
import { useDispatch, useSelector } from "react-redux";
import { selectIsSidebarOpened } from "../../store/selectors/sidebarSelector";
import { toggleSetSidebarOpen } from "../../store/actions/sidebar/sidebarActions";
import useIsMobile from "../../hooks/useIsMobile";

const SidebarNavigation = () => {
  const [firstRender, setFirstRender] = useState(true);
  const dispatch = useDispatch();
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  const toggleSidebar = () => {
    if (isSidebarOpened) dispatch(toggleSetSidebarOpen());
  };
  const { isMobile } = useIsMobile();

  useEffect(() => {
    if (isMobile) {
      setFirstRender(false);
      // dispatch(toggleSetSidebarOpen());
    } else {
      dispatch(toggleSetSidebarOpen());
    }
  }, [isMobile]);

  return (
    <SidebarContainer>
      <SidebarBackdrop
        onClick={toggleSidebar}
        $isSidebarOpened={isSidebarOpened && (!isMobile || !firstRender)}
      />
      <SidebarNavigationContainer
        $isSidebarOpened={isSidebarOpened && (!isMobile || !firstRender)}
      >
        <SidebarUpperContent />
        <SidebarLowerContent />
      </SidebarNavigationContainer>
    </SidebarContainer>
  );
};

SidebarNavigation.propTypes = {
  item: PropTypes.any,
  content: PropTypes.any,
};

export default SidebarNavigation;
