import { Box } from "@mui/material";
import styled, { css } from "styled-components";
import selectedTheme from "../../../../../../themes";

export const FindingScanningSectionContainer = styled(Box)`
  width: 100%;
  display: flex;
  border-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  gap: 16px;
  padding: 8px 12px;
  opacity: ${(props) => props?.$isDragging && "0.2"};
  ${(props) =>
    props?.$selected &&
    css`
      background-color: ${selectedTheme.colors.findingSubjectChosenBg};
      & ${FindingScanningSectionText} {
        color: ${selectedTheme.colors.pageTitleColor};
      }
      & ${FindingScanningSectionNumber} {
        color: ${selectedTheme.colors.pageTitleColor};
      }
      & ${FindingScanningSectionNumberContainer} {
        /* mix-blend-mode: multiply; */
        /* background-color: ${selectedTheme.colors.primaryButtonLightColor}; */
      }
    `}
`;
export const FindingScanningSectionText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  @media (max-width: 600px) {
    max-width: calc(100vw - 208px);
  }
`;
export const FindingScanningSectionSecondText = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 300px;
  @media (max-width: 600px) {
    max-width: calc(100vw - 208px);
  }
`;
export const FindingScanningSectionNumberContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  /* background-color: ${selectedTheme.colors.pagingSelected}; */
`;
export const FindingScanningSectionNumber = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  font-feature-settings: "salt" on;
  color: ${selectedTheme.colors.tableColumnText};
`;
export const FindingScanningStartContainer = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;
`;
export const FindingScanningDropContainer = styled(Box)`
  padding-bottom: ${(props) => props?.$canDrop && "50px"};
  transition: 0.2s;
  display: flex;
`;
