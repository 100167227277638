import { Box } from "@mui/material";
import styled from "styled-components";
import LoginIlustration from "../../Icon/Icons/LoginIlustration";

export const LoginImageContainer = styled(Box)`
  width: 100%;
`;
export const Image = styled(LoginIlustration)`
  width: 100%;
`;
