import React from "react";
import PropTypes from "prop-types";
import {
  DeleteSectionIcon,
  EditSectionIcon,
  TemplateSectionsListItemDetailsContainer,
  TemplateSectionsListItemTitle,
} from "./ListItemDetails.styled";
import Tooltip from "../../../../../../../Tooltip/Tooltip";
import i18n from "../../../../../../../../i18n";

const TemplateSectionsListItemDetails = (props) => {
  const handleEditSectionTitle = () => {
    props?.handleStartEditing();
  };

  const handleDeleteSection = () => {
    props?.handleDeleteSection();
  };

  const handleChooseSection = () => {
    props?.handleChooseSection();
  };

  return (
    <TemplateSectionsListItemDetailsContainer $chosen={props?.chosen}>
      <Tooltip title={props?.title}>
        <TemplateSectionsListItemTitle
          $chosen={props?.chosen}
          onClick={handleChooseSection}
        >
          {props?.title?.length > 20
            ? props?.title?.slice?.(0, 20) + "..."
            : props?.title}
        </TemplateSectionsListItemTitle>
      </Tooltip>
      {!props?.isReadOnly && (
        <>
          <Tooltip title={i18n.t("scanningObjects.tooltip.editSection")}>
            <EditSectionIcon onClick={handleEditSectionTitle} />
          </Tooltip>
          <Tooltip title={i18n.t("scanningObjects.tooltip.removeSection")}>
            <DeleteSectionIcon onClick={handleDeleteSection} />
          </Tooltip>
        </>
      )}
    </TemplateSectionsListItemDetailsContainer>
  );
};

TemplateSectionsListItemDetails.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  handleStartEditing: PropTypes.func,
  chosen: PropTypes.bool,
  handleChooseSection: PropTypes.func,
  handleDeleteSection: PropTypes.func,
  isReadOnly: PropTypes.bool,
};

export default TemplateSectionsListItemDetails;
