import { Box } from "@mui/material";
import styled from "styled-components";
import selectedTheme from "../../themes";
import {
  TableDataCellContainer,
  TableDataRowContainer,
} from "../Table/TableContent/TableData/TableData.styled";
import Title from "../Typography/Title/Title";

export const EntityInputsLabel = styled(Box)`
  font-family: ${selectedTheme.fonts.mainText};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: ${selectedTheme.colors.deviceColor};
  margin-bottom: 8px;
  margin-top: 12px;
`;
export const ManualTableContainer = styled(Box)`
  @media (max-width: 600px) {
    & ${TableDataRowContainer} {
      flex-direction: ${(props) => (props?.$noActions ? "column" : "row")};
    }
    & ${TableDataCellContainer}:nth-child(2) {
      align-items: ${(props) => !props?.$noActions && "center"} !important;
      justify-content: ${(props) =>
        !props?.$noActions && "flex-end"} !important;
    }
  }
`;
export const TableTitle = styled(Title)`
  font-size: 24px;
`;
export const TitleButtonContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
`;
