import React from "react";
import PropTypes from "prop-types";
import { FindingItemTableRowContainer } from "./FindingItemTableRow.styled";
import FindingItemTableCell from "../FindingItemTableCell/FindingItemTableCell";

const FindingItemTableRow = (props) => {
  return (
    <FindingItemTableRowContainer>
      {props?.data?.map?.((singleData, index) => (
        <FindingItemTableCell
          value={singleData?.answer}
          key={index}
          readonly={props?.readonly}
          questionId={props?.questionId}
          rowIndex={props?.rowIndex}
          answerId={singleData?.id}
          firstInLastRow={index === 0 && props?.lastRow}
          lastInLastRow={index === props?.data?.length - 1 && props?.lastRow}
        />
      ))}
    </FindingItemTableRowContainer>
  );
};

FindingItemTableRow.propTypes = {
  data: PropTypes.array,
  firstRow: PropTypes.bool,
  readonly: PropTypes.bool,
  rowIndex: PropTypes.number,
  questionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lastRow: PropTypes.bool,
};

export default FindingItemTableRow;
