import React, { useCallback, useMemo, useRef } from "react";
import PropTypes from "prop-types";
import {
  FindingTabChooserContainer,
  FindingTab,
  TabsVerticalSeparator,
  FindingTabsContainer,
  FindingTabsOutterContainer,
  TabsHorizontalSeparator,
  OtherTabsSelector,
  FindingAllTabsContainer,
  FindingModalButtonsContainer,
  FindingSSTab,
  CustomTooltip,
} from "./FindingTabChooser.styled";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";
import MeasuringInstrumentsModalButton from "./FindingModalButtons/MeasuringInstrumentsModalButton/MeasuringInstrumentsModalButton";
import StandardsModalButton from "./FindingModalButtons/StandardsModalButton/StandardsModalButton";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { selectIsSidebarOpened } from "../../../../store/selectors/sidebarSelector";

const FindingTabChooser = (props) => {
  const { t } = useTranslation();
  // const containerRef = useRef(null);
  const selectRef = useRef(null);
  const tabsRef = useRef(null);
  const { isMobile } = useIsMobile();
  const isSidebarOpened = useSelector(selectIsSidebarOpened);
  const [width, setWidth] = useState(0);
  const [flexWrap, setFlexWrap] = useState("wrap");

  const handleWindowResize = useCallback(() => {
    setWidth(tabsRef.current.clientWidth);
  }, [tabsRef?.current]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (tabsRef?.current) {
      handleWindowResize();
    }
  }, [tabsRef?.current])

  useEffect(() => {
    setWidth(tabsRef.current.clientWidth);
  }, [isSidebarOpened]);
  // Commented due to logic changes, maybe will be needed in future use
  //
  // const [indexOfLastElementShown, setIndexOfLastElementShown] = useState(-1);
  // useEffect(() => {
  //   if (containerRef.current) {
  //     let searchingIndex = -1;
  //     let currentIndex = 0;
  //     while (
  //       searchingIndex === -1 &&
  //       currentIndex !== props?.tabs?.length - 1 &&
  //       props?.tabs?.length !== 0
  //     ) {
  //       if (
  //         containerRef.current?.children?.[currentIndex + 2]?.offsetLeft >
  //         containerRef.current?.children?.[currentIndex + 3]?.offsetLeft
  //       )
  //         searchingIndex = currentIndex;
  //       currentIndex += 1;
  //     }
  //     if (searchingIndex !== -1) setIndexOfLastElementShown(searchingIndex);
  //   }
  // }, [containerRef.current]);

  const tabsToShow = useMemo(() => {
    if (isMobile) return props?.tabs;
    if (width > 150) {
      if (flexWrap !== "wrap") setFlexWrap("wrap");
    } else {
      if (flexWrap !== "nowrap");
      setFlexWrap("nowrap");
    }
    let numberOfTabs = Math.floor((width - 20) / 124)
    if (numberOfTabs < 0) numberOfTabs = 0;
    let firstTabs = props?.tabs?.slice(0, numberOfTabs);
    if (props?.selectedTab?.id === 0) return firstTabs;
    if (
      firstTabs?.find(
        (singleTab) => singleTab?.id === props?.selectedTab?.id
      ) !== undefined
    )
      return firstTabs;
    let selectedTab = props?.tabs?.find(
      (singleTab) => singleTab?.id === props?.selectedTab?.id
    );
    // debugger; //eslint-disable-line
    return [...props?.tabs?.slice(0, numberOfTabs - 1), selectedTab];
  }, [props?.tabs, props?.selectedTab, isMobile, width]);

  const otherTabs = useMemo(() => {
    return props?.tabs?.reduce?.((prevValue, newValue) => {
      if (
        tabsToShow?.find?.((singleTab) => singleTab?.id === newValue?.id) !==
        undefined
      )
        return prevValue;
      prevValue.push({
        label: newValue?.title,
        id: newValue?.id,
      });
      return prevValue;
    }, []);
  }, [tabsToShow]);

  const handleOpenOtherTabs = () => {
    selectRef?.current?.setIsOpened(true);
  };

  const handleChangeTab = (data) => {
    props?.handleChangeTab(JSON.parse(data.target.value));
  };

  return (
    <FindingTabChooserContainer>
      <FindingAllTabsContainer ref={tabsRef}>
        {!props?.insideScanningSubjects && (
          <>
            <FindingTabsOutterContainer>
              <FindingTabsContainer>
                <FindingTab
                  selected={props?.selectedTab?.id === 0}
                  onClick={() => props?.handleChangeTab({ id: 0 })}
                >
                  {t("findings.settings")}
                </FindingTab>
                {props?.isEditing && (
                  <FindingTab
                    selected={props?.selectedTab?.id === -1}
                    onClick={() => props?.handleChangeTab({ id: -1 })}
                  >
                    {t("findings.lastChanges")}
                  </FindingTab>
                )}
              </FindingTabsContainer>
              <TabsHorizontalSeparator />
            </FindingTabsOutterContainer>
            <TabsVerticalSeparator />
          </>
        )}
        <FindingTabsOutterContainer>
          <FindingTabsContainer $isWrapping={flexWrap}>
            {!props?.insideScanningSubjects && (
              <FindingTab
                selected={props?.selectedTab?.id === 1}
                onClick={() => props?.handleChangeTab({ id: 1 })}
              >
                {t("scanningObjects.title")}
              </FindingTab>
            )}
            {tabsToShow?.map((singleTab, index) => {
              return (
                <CustomTooltip key={index} title={singleTab?.title}>
                  <FindingSSTab
                    selected={props?.selectedTab?.id === singleTab?.id}
                    onClick={() => props?.handleChangeTab(singleTab?.id)}
                  >
                    {singleTab?.title}
                  </FindingSSTab>
                </CustomTooltip>
              );
            })}
            {!isMobile && otherTabs?.length > 0 && (
              <FindingTab onClick={handleOpenOtherTabs}>
                <OtherTabsSelector
                  ref={selectRef}
                  options={otherTabs}
                  placeholder="..."
                  onChange={handleChangeTab}
                  hideDefaultValue={true}
                />
              </FindingTab>
            )}
          </FindingTabsContainer>
          <TabsHorizontalSeparator />
        </FindingTabsOutterContainer>
        {!props?.insideScanningSubjects && (
          <>
            <FindingTabsOutterContainer>
              <FindingTabsContainer>
                <FindingTab
                  selected={props?.selectedTab?.id === 2}
                  onClick={() => props?.handleChangeTab({ id: 2 })}
                >
                  {t("findings.schema")}
                </FindingTab>
              </FindingTabsContainer>
              <TabsHorizontalSeparator />
            </FindingTabsOutterContainer>
          </>
        )}
      </FindingAllTabsContainer>
      {!props?.hideModalButtons && (
        <FindingModalButtonsContainer>
          <MeasuringInstrumentsModalButton subjectId={props?.subjectId} />
          <StandardsModalButton subjectId={props?.subjectId} />
        </FindingModalButtonsContainer>
      )}
    </FindingTabChooserContainer>
  );
};

FindingTabChooser.propTypes = {
  tabs: PropTypes.array,
  handleChangeTab: PropTypes.func,
  selectedTab: PropTypes.object,
  isEditing: PropTypes.bool,
  hideModalButtons: PropTypes.bool,
  insideScanningSubjects: PropTypes.bool,
  subjectId: PropTypes.any,
};

FindingTabChooser.defaultProps = {
  tabs: [],
};

export default FindingTabChooser;
