import React from "react";
import PropTypes from "prop-types";
import TextareaInput from "../../../../../Field/TextareaInput/TextareaInput";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectSingleFindingSettingsOld } from "../../../../../../store/selectors/findingsSelectors";

const DescriptionField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  return (
    <TextareaInput
      placeholder={t("findings.form.description.placeholder")}
      title={t("findings.form.description.title")}
      name={findingFormikConstants.description}
      value={formik?.values?.[findingFormikConstants.description].value}
      onChange={(e) =>
        formik?.setFieldValue?.(findingFormikConstants.description, {
          edited: e?.target?.value !== findingSettingsOld?.description?.value,
          value: e.target.value,
        })
      }
      disabled={props?.disabled}
      error={
        formik?.touched?.[findingFormikConstants.description]?.value &&
        formik?.errors?.[findingFormikConstants.description]?.value
      }
    />
  );
};

DescriptionField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default DescriptionField;
