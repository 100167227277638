import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import ManualTable from "../../../../ManualTable/ManualTable";
import { useTranslation } from "react-i18next";
import findingStaffsTableConstants from "../../../../../constants/tableConstants/findingTables/findingStaffsTableConstants";
import TextRenderer from "../../../../Table/Renderers/TextRenderer/TextRenderer";
import { FindingStaffsContainer } from "./FindingStaffs.styled";
import { fetchStuffs } from "../../../../../store/actions/stuffs/stuffsActions";
import { selectStuffsData } from "../../../../../store/selectors/stuffsSelector";
import { useDispatch, useSelector } from "react-redux";
import {
  setFindingsChanged,
  setSingleFindingStaffs,
} from "../../../../../store/actions/findings/findingsActions";
import {
  selectIsFindingSettingsChanged,
  selectSingleFindingStaffs,
} from "../../../../../store/selectors/findingsSelectors";
import { toggleAddFindingStaffModal } from "../../../../../store/actions/modal/modalActions";

const FindingStaffs = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isFindingChanged = useSelector(selectIsFindingSettingsChanged);
  const staffs = useSelector(selectSingleFindingStaffs);

  const changeEntity = () => {
    if (!isFindingChanged) dispatch(setFindingsChanged(true));
  };

  const tableColumns = useMemo(() => {
    return Object.keys(findingStaffsTableConstants).map((property) => {
      if (property === "actions" && props?.disabled) return;
      return {
        ...findingStaffsTableConstants[property],
        field: t(findingStaffsTableConstants[property].i18key),
        propertyName:
          findingStaffsTableConstants[property]?.property || property,
        backendProperty:
          findingStaffsTableConstants[property]?.backendProperty || property,
        style: findingStaffsTableConstants[property]?.style,
        renderer:
          findingStaffsTableConstants[property]?.renderer || TextRenderer,
      };
    });
  }, [findingStaffsTableConstants, t, props?.disabled]);

  const mapDataFunction = useCallback(
    (newData) => {
      return newData.map((singleData) => {
        if (singleData == null) return;
        let mappedObject = {
          stuffs: `${singleData?.firstName} ${singleData?.lastName}`,
          staffRole: singleData?.staffRole,
          actions: {
            id: singleData?.id,
            stuffs: singleData.stuffs,
            staffObject: singleData,
          },
        };
        if (props?.disabled) delete mappedObject.delete;
        return mappedObject;
      });
    },
    [props?.disabled]
  );

  const handleRemoveStaff = (staff) => {
    let newStaffsArray = [...staffs];
    let staffIndex = newStaffsArray.findIndex(
      (entity) => entity.value.id === staff.id
    );
    if (newStaffsArray[staffIndex]?.added)
      newStaffsArray?.splice(staffIndex, 1);
    else
      newStaffsArray?.splice(staffIndex, 1, {
        value: staffs[staffIndex]?.value,
        removed: true,
      });
    dispatch(setSingleFindingStaffs(newStaffsArray));
  };

  const handleClickAddButton = () => {
    dispatch(
      toggleAddFindingStaffModal({
        title: t("findings.staffs.newStaff"),
        isEditing: false,
        rank: 1,
      })
    );
  };

  return (
    <FindingStaffsContainer>
      <ManualTable
        disabled={props?.disabled}
        key={JSON.stringify(
          staffs
            ?.filter?.((singleStaff) => !singleStaff?.removed)
            ?.map?.((singleStaff) => singleStaff?.value)
        )}
        fetchedData
        selectedData={staffs
          ?.filter?.((singleStaff) => !singleStaff?.removed)
          ?.map?.((singleStaff) => singleStaff?.value)}
        title={t("findings.staffs.title")}
        selectPlaceholder={t("findings.staffs.chooseStaff")}
        fetchEntitiesDispatchFunction={fetchStuffs}
        entityDataSelector={selectStuffsData}
        mapDataFunction={mapDataFunction}
        tableColumns={tableColumns}
        onChangeEntity={changeEntity}
        namePropertyOfEntity={["firstName", "lastName"]}
        onRemoveEntity={handleRemoveStaff}
        dropdownLabel={["firstName", "lastName"]}
        buttonTitle={t("common.add")}
        handleClickAddButton={handleClickAddButton}
        noActions
      />
    </FindingStaffsContainer>
  );
};

FindingStaffs.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
};

export default FindingStaffs;
