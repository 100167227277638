import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  TableDataCellContainer,
  TableDataContainer,
  TableDataRowContainer,
} from "./TableData.styled";
import { useDispatch, useSelector } from "react-redux";
import { selectModalValues } from "../../../../store/selectors/modalSelectors";
import { useTranslation } from "react-i18next";
import useIsMobile from "../../../../hooks/useIsMobile";

const TableData = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const modals = useSelector(selectModalValues);
  const { isMobile } = useIsMobile();
  const handleRowClick = (rowData, rowIndex) => {
    setSelectedRowIndex(rowIndex);
    if (props?.onRowClick) props?.onRowClick(rowData, rowIndex);
  };
  // debugger; //eslint-disable-line

  const handleMouseEnter = (rowIndex) => {
    props?.setHoveredItem(rowIndex);
  }
  const handleMouseLeave = () => {
    props?.setHoveredItem(-1);
  }

  return (
    <TableDataContainer>
      {/* Change key to item id after connecting with BE */}
      {props?.data?.map?.((row, index) => {
        if (row == null) return;
        let propertyNames = Object.getOwnPropertyNames(row);
        propertyNames = propertyNames?.filter?.((el) => el !== "id");
        if (props?.stickyActions && props?.stickyTable == null) {
          propertyNames = propertyNames?.filter?.((el) => el !== "actions");
        }
        if (props?.stickyTable)
          propertyNames = propertyNames?.filter?.((el) => el === "actions");
        let newPropertyNames = propertyNames?.filter?.(
          (singlePropertyName, index) =>
            !props?.columns?.find((column) => column?.id === index)
              ?.hideOnMobile
        );
        let propertyNamesToIterate = isMobile
          ? newPropertyNames
          : propertyNames;
        return (
          <TableDataRowContainer
            $isHovered={index === props?.hoveredItem}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
            key={index}
            selected={
              selectedRowIndex === index &&
              Object.values(modals).some((el) => el === true)
            }
            onClick={() => handleRowClick(row, index)}
            redirect={props?.columns?.[0]?.redirect}
            isMobile={isMobile}
          >
            {propertyNamesToIterate?.map?.((cell, index2) => {
              const column = props?.columns?.find?.(
                (column) => column?.propertyName === cell
              );
              return (
                <TableDataCellContainer
                  minWidth={`calc(100% / ${propertyNamesToIterate?.length})`}
                  maxWidth={
                    column?.maxWidth &&
                    `calc(100% / ${propertyNamesToIterate?.length})`
                  }
                  key={index2}
                  style={
                    column?.mobileStyle == null || !isMobile
                      ? column?.style
                      : column?.mobileStyle
                  }
                  isMobile={isMobile}
                >
                  {column?.renderer?.({
                    value: row[cell],
                    label: t(column?.i18key),
                    redirect: column?.redirect,
                    showLabel: column?.showLabel,
                    redirectHref: props?.redirectHref,
                    redirectFunction: props?.redirectFunction,
                    redirectHrefFunction: props?.redirectHrefFunction,
                    dataId: row?.id,
                    prefilledData: row,
                    dispatch,
                    rendererProps: column?.rendererProps,
                    smallerText: props?.smallerTextForMobile,
                    isMobile: isMobile,
                  })}
                </TableDataCellContainer>
              );
            })}
          </TableDataRowContainer>
        );
      })}
    </TableDataContainer>
  );
};

TableData.propTypes = {
  columns: PropTypes.array,
  data: PropTypes.array,
  redirectHref: PropTypes.string,
  onRowClick: PropTypes.func,
  redirectFunction: PropTypes.func,
  redirectHrefFunction: PropTypes.func,
  smallerTextForMobile: PropTypes.bool,
  stickyActions: PropTypes.bool,
  stickyTable: PropTypes.bool,
  hoveredItem: PropTypes.number,
  setHoveredItem: PropTypes.func,
};

TableData.defaultProps = {
  data: [],
};

export default TableData;
