import React from "react";
import PropTypes from "prop-types";
import {
  ActiveUser,
  DeactiveUser,
  NavText,
  TabsContainer,
  UsersContentContainer,
} from "./UsersContent.styled";
import Title from "../Typography/Title/Title";
import { useTranslation } from "react-i18next";
import ActiveUsersTable from "./UsersTable/ActiveUsersTable/UsersTable";
import DeactiveUsersTable from "./UsersTable/DeactiveUsersTable/UsersTable";
import { useState } from "react";

const UsersContent = () => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState(true);
  return (
    <UsersContentContainer>
      <Title>{t("users.title")}</Title>
      <TabsContainer>
        <ActiveUser active={activeTab} onClick={() => setActiveTab(true)}>
          <NavText>{t("users.tabActive")}</NavText>
        </ActiveUser>
        <DeactiveUser active={activeTab} onClick={() => setActiveTab(false)}>
          <NavText>{t("users.tabDeactive")}</NavText>
        </DeactiveUser>
      </TabsContainer>
      {activeTab ? <ActiveUsersTable /> : <DeactiveUsersTable />}
    </UsersContentContainer>
  );
};

UsersContent.propTypes = {
  children: PropTypes.node,
};

export default UsersContent;
