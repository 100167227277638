import React from "react";
import PropTypes from "prop-types";
import { forwardRef } from "react";
import {
  ChapterContainer,
  ChapterTitle,
  TextInput,
} from "../../SetScanningObjectModal.styled";
import {
  AddColumnIcon,
  AddColumnText,
  AddContainer,
  ColumnsContainer,
  PreviewContainer,
  PreviewIcon,
  PreviewText,
  RemoveColumnIcon,
  TitleContainer,
} from "./TableTypeInputs.styled";
import { useState } from "react";
import { useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { toggleTablePreviewModal } from "../../../../../store/actions/modal/modalActions";

const TableTypeInputs = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState(
    props?.columns[0] !== "" && props?.columns?.length > 0
      ? props?.columns?.sort?.(function (a, b) {
          return a?.columnIndex - b?.columnIndex;
        })
      : [{ columnTitle: "", columnSubtitle: "", columnIndex: 0 }]
  );
  const { t } = useTranslation();

  useImperativeHandle(ref, () => ({
    columns,
    setColumns,
  }));

  const handleChangeValue = (newValue, id, isTitle) => {
    setColumns((prevColumns) => {
      let newColumns = [...prevColumns];
      let selectedColumn = newColumns?.find?.(
        (column) => column?.columnIndex === id
      );
      if (isTitle) {
        selectedColumn = { ...selectedColumn, columnTitle: newValue };
      } else {
        selectedColumn = { ...selectedColumn, columnSubtitle: newValue };
      }
      newColumns?.splice(
        newColumns?.findIndex(
          (singleColumn) => singleColumn?.columnIndex === id
        ),
        1,
        selectedColumn
      );
      return [...newColumns];
    });
  };
  const handleRemoveColumn = (index) => {
    setColumns((prevColumns) => {
      let newColumns = [...prevColumns];
      newColumns?.splice?.(index, 1);
      if (newColumns?.length === 0)
        newColumns = [{ columnTitle: "", columnSubtitle: "", columnIndex: 0 }];
      return [...newColumns];
    });
  };
  const handleAddColumn = () => {
    var maxIndex = Math.max(...columns?.map?.((o) => o?.columnIndex)) || 0;
    setColumns((prevColumns) => [
      ...prevColumns,
      { columnTitle: "", columnSubtitle: "", columnIndex: maxIndex + 1 },
    ]);
  };

  const handleTablePreview = () => {
    dispatch(toggleTablePreviewModal({ rank: 2, columns }));
  };

  return (
    <>
      <ChapterContainer>
        <TitleContainer>
          <ChapterTitle sx={{ flex: 1 }}>
            {t("questions.columnTitle")}
          </ChapterTitle>
          <ChapterTitle sx={{ flex: 1 }}>
            {t("questions.columnSubtitle")}
          </ChapterTitle>
        </TitleContainer>
        {columns?.map((singleColumn, index) => (
          <ColumnsContainer key={singleColumn?.columnIndex}>
            <TextInput
              placeholder={t("questions.columnTitle")}
              value={singleColumn?.columnTitle}
              inputProps={{
                style: {
                  flex: 1,
                },
              }}
              onChange={(event) =>
                handleChangeValue(
                  event.target.value,
                  singleColumn?.columnIndex || 0,
                  true
                )
              }
            />
            <TextInput
              placeholder={t("questions.columnSubtitle")}
              value={singleColumn?.columnSubtitle}
              inputProps={{
                style: {
                  flex: 1,
                },
              }}
              onChange={(event) =>
                handleChangeValue(
                  event.target.value,
                  singleColumn?.columnIndex || 0,
                  false
                )
              }
            />
            <RemoveColumnIcon onClick={() => handleRemoveColumn(index)} />
          </ColumnsContainer>
        ))}
        <AddContainer onClick={handleAddColumn}>
          <AddColumnIcon />
          <AddColumnText> {t("questions.addColumn")}</AddColumnText>
        </AddContainer>
        <PreviewContainer onClick={handleTablePreview}>
          <PreviewIcon />
          <PreviewText>{t("questions.previewTable")}</PreviewText>
        </PreviewContainer>
      </ChapterContainer>
    </>
  );
});

TableTypeInputs.displayName = "TableTypeInputs";

TableTypeInputs.propTypes = {
  defaultAnswerIndex: PropTypes.number,
  columns: PropTypes.array,
};
TableTypeInputs.defaultProps = {
  columns: [{ columnTitle: "", columnSubtitle: "", columnIndex: 0 }],
};

export default TableTypeInputs;
