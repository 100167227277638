import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { findingFormikConstants } from "../../../../../../initialValues/findingInitialValues";
import { FindingTextField } from "../FindingSettingsForm.styled";
import { selectSingleFindingSettingsOld } from "../../../../../../store/selectors/findingsSelectors";
import { useSelector } from "react-redux";

const FacilityField = (props) => {
  const formik = props?.formik;
  const { t } = useTranslation();
  const findingSettingsOld = useSelector(selectSingleFindingSettingsOld);
  return (
    <FindingTextField
      name={findingFormikConstants.facility}
      disabledField={props?.disabled}
      label={t("findings.form.facility.title")}
      placeholder={t("findings.form.facility.placeholder")}
      value={formik?.values?.[findingFormikConstants.facility].value}
      onChange={(e) =>
        formik?.setFieldValue?.(findingFormikConstants.facility, {
          edited: e?.target?.value !== findingSettingsOld?.facility?.value,
          value: e.target.value,
        })
      }
      error={
        formik?.touched?.[findingFormikConstants.facility]?.value &&
        formik?.errors?.[findingFormikConstants.facility]?.value
      }
      helperText={
        formik?.errors?.[findingFormikConstants.facility]?.value &&
        formik?.touched?.[findingFormikConstants.facility]?.value
      }
      fullWidth
    />
  );
};

FacilityField.propTypes = {
  formik: PropTypes.object,
  disabled: PropTypes.bool,
};

export default FacilityField;
