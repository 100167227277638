import styled, { css } from "styled-components";
import ImagePicker from "../../../ImagePicker/ImagePicker";

export const CalibrationCertificatePicker = styled(ImagePicker)`
  flex: 1;
  ${(props) =>
    props?.$hasImage &&
    css`
      /* padding: 8px; */
      width: 412px !important;
      max-width: 412px !important;
      height: 420px !important;
      max-height: 420px !important;
      min-height: 420px !important;
      & > form {
        padding: ${(props) => !props?.image && "8px"};
      }
      & p {
        text-align: center;
      }
      & * {
        max-width: 412px !important;
        max-height: 420px !important;
      }
    `}
`;
